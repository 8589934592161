import React from "react"
import Seo from "../../components/Seo/Seo"
import PageTransition from "../../components/PageTransition";
import './special-thanks.sass'
import ReactGA from 'react-ga';
import IconGatsby from '../../images//icons/gatsby.inline.svg'
import IconGithub from '../../images/icons/github.inline.svg'
import IconGraphql from '../../images/icons/graphql.inline.svg'
import IconNetlify from '../../images/icons/netlify.inline.svg'
import IconReact from '../../images/icons/react.inline.svg'
import IconRedux from '../../images/icons/redux.inline.svg'
import IconSimpleIcons from '../../images/icons/simpleicons.inline.svg'
import IconVisualStudioCode from '../../images/icons/visualstudiocode.inline.svg'

const CreditsPage = (props) => {

	return (

		<PageTransition {...props}>

			<Seo title="Special Thanks" />

			<div className="contentPage page">

				<h1 id="main-title">Special Thanks</h1>

				<p>Over the years Deviant Robot is made possible due to the help and support of a lot of lovely people, projects and organisation. We want to say thank you to everyone who has helped us over the years and make it clear that this is far from a definitive list of those who deserve our gratitude.</p>

				<p>Thanks to the many Deviant Robot podcast cast members over the years,  including: -</p>

				<div className="thank-you-list">

					<ul>
						<li>Charlie Cox</li>
						<li>Dave Convery</li>
						<li>Dom O'Brien</li>
						<li>Dziugas Matas</li>
						<li>Hannah Capocci-Hunt</li>
						<li>Ian Thompson</li>
					</ul>

					<ul>
						<li>James (Jimmy) Lucktaylor</li>
						<li>Pawel Michalowski</li>
						<li>Robin Young</li>
						<li>Zainabb hull</li>
						<li>Ziwei Deng</li>
					</ul>

				</div>

				<p>Special thank you to <ReactGA.OutboundLink eventLabel="ErmanKutluDesign" to="http://ermankutlu.co.uk/">Erman Kutlu (http://ermankutlu.co.uk/)</ReactGA.OutboundLink> for help with the Deviant Robot logo.</p>

				<div className="things-we-use">

					<h2>Things we used to make the website</h2>

					<ul>

						<li><a href="https://reactjs.org/"><IconReact alt="React" /></a></li>
						<li><a href="https://redux.js.org/"><IconRedux alt="Redux" /></a></li>
						<li><a href="https://www.gatsbyjs.org/"><IconGatsby alt="Gatsby" /></a></li>
						<li><a href="https://graphql.org/"><IconGraphql alt="GraphQL" /></a></li>
						<li><a href="https://github.com/"><IconGithub alt="GitHub" /></a></li>
						<li><a href="https://www.netlify.com/"><IconNetlify alt="Netlify" /></a></li>
						<li><a href="https://code.visualstudio.com/"><IconVisualStudioCode alt="Visual Studio Code" /></a></li>
						<li><a href="https://simpleicons.org/"><IconSimpleIcons alt="Simple Icons" /></a></li>

					</ul>

				</div>

			</div>

		</PageTransition>

	)

}
export default CreditsPage
